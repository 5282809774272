.footer-container {
    padding-left: 15px;
    padding-right: 15px;
  }
  
  /* Logo styling */
  .footer-logo {
    height: 90px;
    width: 180px;
    margin: 0;
    padding: 0;
    background-color: white;
  }
  
  /* Map container responsive */
  .map-container {
    width: 100%;
    max-width: 100%;
    height: 180px;
    overflow: hidden;
  }
  
  /* Media queries for mobile view */
  @media (max-width: 576px) {
    .footer-container {
      padding-left: 10px;
      padding-right: 10px;
    }
  
    .col-lg-3, .col-md-6 {
      margin-bottom: 30px;
      text-align: center;
    }
  
    .footer-logo {
      margin: 0 auto;
    }
  
    h4, p, a {
      text-align: center;
    }
  
    iframe {
      height: 250px; /* Adjust height for mobile view */
    }
  }
  