.contact-page {
    font-family: Arial, sans-serif;
  }
  
  .page-header {
    background-color: #f8f9fa;
    padding: 50px 0;
    margin-bottom: 50px;
  }
  
  .page-header h1 {
    margin: 0;
    color: #333;
  }
  
  .breadcrumb {
    background: none;
    padding: 0;
  }
  
  .breadcrumb a {
    color: #007bff;
    text-decoration: none;
  }
  
  .contact-info-section {
    margin-bottom: 0px;
  }
  
  .contact-info-item {
    text-align: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
    margin-bottom: 20px;
  }
  
  .contact-info-item i {
    font-size: 2em;
    color: #007bff;
    margin-bottom: 10px;
  }
  
  .contact-info-item h5 {
    margin: 0;
    color: #333;
  }
  
  .contact-form-section {
    background-color: #f8f9fa;
    padding: 50px 0;
  }
  
  .contact-form {
    background-color: #fff;
    padding: 30px;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1);
  }
  
  .form-control {
    margin-bottom: 20px;
  }
  
  .btn-primary {
    background-color: #ffaa17;
    border: none;
    padding: 10px 30px;
  }
  
  .btn-primary:hover {
    background-color: #ffaa17;
  }
  
  .alert {
    margin-bottom: 20px;
  }