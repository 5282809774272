/* General modal styles */
.modal {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    z-index: 9999;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0, 0, 0, 0.8);
}

.modal-content {
    position: relative;
    width: auto;
    max-width: 90%;
    max-height: 90%;
}

.modal-image {
    width: 150%;
    height: auto;
}

.close {
    position: absolute;
    top: 15px;
    right: 25px;
    color: white;
    font-size: 30px;
    cursor: pointer;
}

/* Responsive Styles */
@media (max-width: 768px) {
    .img-fluid {
        width: 100%; /* Make images full width on mobile */
        height: auto; /* Maintain aspect ratio */
    }

    .modal-image {
        max-width: 95%; /* Reduce max-width for mobile */
    }

    .modal-content {
        max-height: 80%; /* Limit modal height for mobile */
    }

    .close {
        font-size: 24px; /* Smaller close button on mobile */
    }

    h1.display-4 {
        font-size: 1.5rem; /* Smaller heading on mobile */
    }

    h5, h4 {
        font-size: 1rem; /* Smaller subheadings on mobile */
    }
}

@media (max-width: 576px) {
    .close {
        top: 10px; /* Adjust close button position for small screens */
        right: 15px;
    }

    h1.display-4 {
        font-size: 1.25rem; /* Even smaller heading on extra small screens */
    }
}
